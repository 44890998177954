import { UPLOAD_CONFIG } from '@/config/upload'

type T_UploadOptions = {
  file: File,
  includeFileName?: boolean
}

type T_UploadCallbacks = {
  onError: (target: EventTarget | null) => void
  onProgress: (rate: number) => void,
  onComplete: (res: T_UploadRes) => void,
  onLoadStart?: () => void
}

export type T_UploadRes = {
  bucketName: string,
  etag: string,
  key: string,
  url: string,
  versionId: string,
}

/* 公共服务的上传接口 */
export function uploadFile(options: T_UploadOptions, callbacks?: T_UploadCallbacks): Promise<T_UploadRes> {
  const url = `${UPLOAD_CONFIG.BaseURL}/file/upload`
  const { file } = options,
    form = new FormData(),
    xhr = new XMLHttpRequest()

  form.append('files', file)
  form.append('bucketName', UPLOAD_CONFIG.BucketName)
  if (options.includeFileName) {
    form.append('includeFileName', 'true')
  }
  
  xhr.open('post', url, true)

  return new Promise((resolve, reject) => {
    const onError = (event: Event) => {
      const { target } = event
      callbacks && callbacks.onError && callbacks.onError(target)
      reject(target)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    xhr.onload = (res: any) => {
      const { target: { status, responseText } } = res
      if (/^(200|202)$/.test(status)) {
        try {
          const response = JSON.parse(responseText)
          const data = response.data
          callbacks && callbacks.onComplete && callbacks.onComplete(data[0])
          resolve(data[0])
        } catch (e) {
          onError(res)
        }
      } else {
        onError(res)
      }
    }

    xhr.onerror = onError
    xhr.upload.onprogress = ({ total, loaded }) => {
      const rate = (loaded / total) * 100
      callbacks &&
        callbacks.onProgress &&
        callbacks.onProgress(parseFloat(rate.toFixed(2)))
    }
    if (callbacks && callbacks.onLoadStart) {
      xhr.upload.onloadstart = callbacks.onLoadStart
    }

    xhr.send(form)
  })
}