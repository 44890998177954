






























































































import { computed, defineComponent, getCurrentInstance, ref, UnwrapRef } from '@vue/composition-api'
import ProfileCard from '@/components/Profile/Card.vue'
import ImageSingleUploader from '@/components/Tools/ImageSingleUploader.vue'
import { useProfile } from '@/hook/useBaseInfo'
import { Form } from 'vant'
import { T_SellerCardInfo } from '@/api/profile'

const UploadAccept = 'image/png, image/jpg'

const Rules = {
  headPic: [
    { required: true, message: '头像不能为空' },
  ],
  userName: [
    { required: true, message: '名字不能为空' },
    { validator: lengthValidate.bind(null, 12), message: '名字最长 12 个字符' }
  ],
  companyName: [
    { required: true, message: '公司名不能为空' },
    { validator: lengthValidate.bind(null, 12), message: '公司名最长 12 个字符' }
  ],
  position: [
    { validator: lengthValidate.bind(null, 12), message: '职位最长 12 个字符' }
  ],
  phone: [
    { required: true, message: '电话号码不能为空' },
    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的电话号码' }
  ],
  weiChatQrCode: [
    { required: true, message: '名片二维码不能为空' },
  ],
  workingTime: [
    
  ]
}

function lengthValidate(num: number, value: string) {
  return value.length <= num
}

function useWorkingTimeSelect(sellerCardInfo: UnwrapRef<T_SellerCardInfo>) {
  const MaxWorkingTime = 80
  const defaultWorkingTimeIndex = computed(() => {
    return Number(sellerCardInfo.workingTime || 5) - 1 // 1、索引值计算需减一  2、默认选中 5 天
  })

  const isShowWorkingTimeSelectPanel = ref(false)

  const WorkingTimeList = []
  for (let i = 1; i <= MaxWorkingTime; i++) {
    WorkingTimeList.push(`${i}`)
  }

  function confirmSelectWorkingTime(value: string) {
    sellerCardInfo.workingTime = value
    isShowWorkingTimeSelectPanel.value = false
  }

  return {
    isShowWorkingTimeSelectPanel,
    WorkingTimeList,
    confirmSelectWorkingTime,
    defaultWorkingTimeIndex
  }
}

export default defineComponent({
  components: {
    ProfileCard,
    ImageSingleUploader
  },
  setup() {
    const {
      isProfileLoaded,
      sellerCardInfo,
      updateSellerCardInfo
    } = useProfile()
    const ctx = getCurrentInstance()

    async function saveCard() {
      const form = ctx?.proxy.$refs.form as Form
      await form.validate()
      updateSellerCardInfo()
    }

    return {
      Rules,
      UploadAccept,

      isProfileLoaded,
      sellerCardInfo,
      updateSellerCardInfo,
      saveCard,

      ...useWorkingTimeSelect(sellerCardInfo)
    }
  },
})
