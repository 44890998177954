


















import { defineComponent, PropType } from '@vue/composition-api'
import { uploadFile } from '@/shared/upload'
import { ylLog } from '@/shared/log'
import Compressor from 'compressorjs'

const MaxSize = 500 * 1024

function compressImage(file: File): Promise<File | Blob> {
  let quality = 1
  if (file.size > MaxSize) {
    quality = MaxSize / file.size
  }
  return new Promise((resolve, reject) => {
    ylLog('Compressor file quality', quality)
    new Compressor(file, {
      quality,
      success: resolve,
      error: reject
    })
  })
}

/*
  文档: https://youzan.github.io/vant/?source=vuejsorg#/zh-CN/uploader
*/

type T_VanFile = {
  file: File,
  content: string, // base64
  message: string,
  status: 'uploading' | 'failed' | 'done'
}

export default defineComponent({
  props: {
    urlList: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: ['change'],
  data() {
    return {
      fileList: this.urlList.map(url => {
        return { url, imageFit: 'contain', }
      })
    }
  },
  computed: {
  },

  methods: {
    afterUpload(file: T_VanFile) {
      file.status = 'uploading'
      compressImage(file.file).then((newFile) => {
        return uploadFile({ file: newFile as File })
      }).then(({ url }) => {
        const newUrlList = [ url ]
        file.status = 'done'
        this.$emit('change', newUrlList)
      }).catch(err => {
        file.status = 'failed'
        ylLog('upload file error', err)
      })
    }
  }
})
