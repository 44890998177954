









import { defineComponent, reactive } from '@vue/composition-api'
import ImageUploader from './ImageUploader.vue'

export default defineComponent({
  components: {
    ImageUploader
  },
  model: {
    prop: 'url',
    event: 'change'
  },
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const urlList = reactive<string[]>([])
    if (props.url) {
      urlList.push(props.url)
    }

    function onUrlChanged(newUrlList: string[]) {
      urlList.length = 0
      urlList.push(...newUrlList)
      if (urlList) {
        emit('change', urlList[0])
      }
    }

    return {
      urlList,
      onUrlChanged
    }
  },
})
